.mainwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;
    background-image: url("../images/about/bg.png");
    background-size: cover;
    position: relative;
    /* overflow: hidden; */
}
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding:10px;
    
  }

  .body_wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    gap: 10px;
  }

  .image_wrapper{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .title{
    color: #f47931;
    font-family: var(--font-comictoon);
    margin: 0;
    -webkit-text-stroke: 2px black;
  }

  .text{
    color:white;
    margin: 0;
    font-family: var(--font-patrick-hand);
  }

  .banner{
    position: absolute;
    width: 100%;
    height: 75px;
    left: 0;
    object-fit:cover;
    z-index: 1;
  }

  @media screen and (max-width:500px) {

    .mainwrapper{
        max-height: 740px;
        min-height: 700px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: column;
    }

    .body{
        padding: 100px;
        padding-top:75px;
        gap:25px;
    }

    .body_wrapper{
        width: 100%;
        align-items: center;
        padding: 30px;
    }

    .image_wrapper{
        width: 100%;
    }

    .image{
        width:260px;
        object-fit: contain;
    }

    .title{
        font-size:44px;
    }

    .text{
        font-size: 16px;
        line-height:24px;
        text-align: center;
    }

    .banner{
        height:20px;
        top: -10px;
    }

  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .mainwrapper{
        max-height: 980px;
        min-height: 900px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: column;
    }

    .body{
        padding: 100px;
        padding-top:100px;
        gap:25px;
    }

    .body_wrapper{
        width: 100%;
        align-items: center;
        padding: 30px;
    }

    .image_wrapper{
        width: 100%;
    }

    .image{
        width:360px;
        object-fit: contain;
    }

    .title{
        font-size: 56px;
    }

    .text{
        font-size: 20px;
        line-height:26px;
        text-align: center;
    }

    .banner{
        height:40px;
        top: -20px;
    }

  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .mainwrapper{
        max-height: 880px;
        min-height: 800px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .body{
        padding: 100px;
        padding-top:150px;
        gap:25px;
    }

    .body_wrapper{
        width: 50%;
        align-items: flex-start;
        padding: 30px;
    }

    .image_wrapper{
        width: 50%;
    }

    .image{
        width:450px;
        object-fit: contain;
    }

    .title{
        font-size: 70px;
    }

    .text{
        font-size: 22px;
        line-height:32px;
        text-align: start;
    }

    .banner{
        height:80px;
        top: -40px;
    }

  }

  @media screen and (min-width:1921px){

    .mainwrapper{
        max-height: 1080px;
        min-height: 1000px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .body_wrapper{
        width: 50%;
        align-items: flex-start;
        padding: 30px;
    }

    .image_wrapper{
        width: 50%;
    }

    .image{
        width:700px;
        object-fit: contain;
    }

    .title{
        font-size: 100px;
    }

    .text{
        font-size: 28px;
        line-height:48px;
        text-align: start;
    }

    .banner{
        height:110px;
        top: -55px;
    }

  }

