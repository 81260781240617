.mainwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding:10px;
  }

  .socialWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .socialicons{
    object-fit: contain;
    height: auto;
  }

  .body{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height:100%;
    box-sizing: border-box;
    position: relative;
  }

  .title{
    color: #f47931;
    font-family: var(--font-comictoon);
    margin: 0;
    -webkit-text-stroke: 5px black;
  }

  .caHeading,.ca{
    margin: 0;
    color: black;
    font-family: var(--font-patrick-hand);
    
  }

  .cawrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #e2ca32;;
    border-radius: 100px;
    border: 2px solid;
  }

  .banner{
    position: absolute;
    width: 100%;
    left: 0;
    object-fit:cover;
    z-index: 1;
    top: 0%;
  }

  .subtitle{
    font-family: var(--font-bubbler);
    color: white;
    margin: 0px;
  }

  .penguin{
    position: absolute;
  }

  .copyWrapper{
    margin: 0;
    background-color: transparent;
    border: none;
  }

  /* .ca{
    max-width: 75%;
  } */

  @media screen and (max-width:500px) {

    .mainwrapper{
        max-height: 550px;
        min-height: 530px;
        background-image: url("../images/Section1/bg4.png");
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .body{
        padding-top:50px;
        gap:25px;
        justify-content: center;
    }

    .banner{
        top:92%;
        height:40px;
    }
    
    .socialWrapper{
        gap: 14px;
    }

    .socialicons{
        width: 24px;
        border-radius: 100px;
    }

    .subtitle{
        font-size: 40px;
    }

    .cawrapper{
        padding:10px;
        gap: 10px;
    }

    .caHeading,.ca{
        font-size:12px;
    }

    .copyIcon{
        width:12px;
        height: auto;
    }

    .title{
        font-size:120px;
    }

    .penguin{
        content: url('../images/Section1/gif-3.gif');
        top:-3%;
        height:300px;
        object-fit: contain;
    }

  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .mainwrapper{
        max-height: 880px;
        min-height: 800px;
        background-image: url("../images/Section1/bg4.png");
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .body{
        padding-top:70px;
        gap:25px;
        justify-content: center;
    }

    .banner{
        top:92%;
        height:75px;
    }
    
    .socialWrapper{
        gap: 30px;
    }

    .socialicons{
        width:40px;
        border-radius: 100px;
    }

    .subtitle{
        font-size: 30px;
    }

    .cawrapper{
        padding:14px;
        gap: 10px;
    }

    .caHeading,.ca{
        font-size:14px;
    }

    .copyIcon{
        width:14px;
        height: auto;
    }

    .title{
        font-size:180px;
    }

    .penguin{
        content: url('../images/Section1/gif-3.gif');
        top:0%;
        height:450px;
        object-fit: contain;
    }
  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .mainwrapper{
        max-height: 980px;
        min-height: 900px;
        background-image: url("../images/Section1/bg3.png");
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .body{
        padding-top:70px;
        gap:25px;
        justify-content: center;
    }

    .banner{
        top:92%;
        height: 150px;
    }
    
    .socialWrapper{
        gap: 40px;
    }

    .socialicons{
        width:44px;
        border-radius: 100px;
    }

    .subtitle{
        font-size: 50px;
    }

    .cawrapper{
        padding:16px;
        gap: 10px;
    }

    .caHeading,.ca{
        font-size:16px;
    }

    .copyIcon{
        width:20px;
        height: auto;
    }

    .title{
        font-size:250px;
    }

    .penguin{
        content: url('../images/Section1/gif-3.gif');
        top:-8%;
        height:600px;
        object-fit: contain;
    }

  }

  @media screen and (min-width:1921px){

    .mainwrapper{
        max-height: 1080px;
        min-height: 1080px;
        background-image: url("../images/Section1/bg3.png");
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .body{
        padding: 200px;
        gap:25px;
        justify-content: flex-end;
    }

    .banner{
        top:92%;
        height: 200px;
    }
    
    .socialWrapper{
        gap: 60px;
    }

    .socialicons{
        width:60px;
        border-radius: 100px;
    }

    .subtitle{
        font-size: 50px;
    }

    .cawrapper{
        padding: 20px;
        gap: 10px;
    }

    .caHeading,.ca{
        font-size:24px;
    }

    .copyIcon{
        width:26px;
        height: auto;
    }

    .title{
        font-size:300px;
    }

    .penguin{
        content: url('../images/Section1/gif-3.gif');
        top:-10%;
        height:750px;
        object-fit: contain;
    }

  }

