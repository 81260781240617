.mainwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;
    background-size: cover;
    position: relative;
    /* overflow: hidden; */
}
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding:10px;    
  }

  .banner{
    position: absolute;
    width: 100%;
    left: 0;
    object-fit:cover;
    z-index: 1;
  }


  @media screen and (max-width:500px) {

    .mainwrapper{
        max-height: 450px;
        min-height: 450px;
        background-image: url("../images/buynow/buynow2.gif");
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: column;
    }

    .banner{
        height:20px;
        top: -10px;
    }

  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .mainwrapper{
        max-height: 750px;
        min-height: 750px;
        background-image: url("../images/buynow/buynow2.gif");
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: column;
    }

    .banner{
        height:40px;
        top: -20px;
    }

  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .mainwrapper{
        max-height: 880px;
        min-height: 800px;
        background-image: url("../images/buynow/buynow.gif");
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .banner{
        height:80px;
        top: -40px;
    }
  }

  @media screen and (min-width:1921px){

    .mainwrapper{
        max-height: 1080px;
        min-height: 1000px;
        background-image: url("../images/buynow/buynow.gif");
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .banner{
        height:110px;
        top: -55px;
    }

  }

